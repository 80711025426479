import React from 'react'
import { navigate } from 'gatsby-link'
import { Link } from 'gatsby'
import cert1 from '../../img/cert1.png';
import cert2 from '../../img/cert2.png';
import Layout from '../../components/Layout'
import left from '../../img/arrow-left-white.svg'
import right from '../../img/arrow-right-white.svg'
import workbookimg from '../../img/workbook-cover.jpg';
import workbookPdf from '../../docs/dbanie_o_siebie_to_nie_egoizm_workbook.pdf';

function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

export default class Index extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showGallery: false,
      currentGalleryIndex: 0,
      isValidated: false 
    }

    this.certs = new Array(cert1, cert2);
  }

  showGallery()
  {
    this.setState({
      showGallery: true,
    });
  }

  hideGallery()
  {
    this.setState({
      showGallery: false,
    });
  }

  rightButtonClicked()
{
  let index = this.state.currentGalleryIndex >= this.certs.length - 1 ? 0 : this.state.currentGalleryIndex + 1;

  this.setState({
    currentGalleryIndex: index
  });
}

leftButtonClicked()
{
  let index = this.state.currentGalleryIndex <= 0 ? this.certs.length - 1 : this.state.currentGalleryIndex - 1;
  
  this.setState({
    currentGalleryIndex: index
  });
}

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value })
    let label = e.target.nextSibling;
    if (e.target.value == '')
    {
        label.classList.remove("hidden")
    }
    else
    {
        label.classList.add("hidden")
    }
  }

  handleSubmit = (e) => {
    e.preventDefault()
    const form = e.target
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        ...this.state,
      }),
    })
      .then(() => navigate(form.getAttribute('action')))
      .catch((error) => alert(error))
  }

  render() {
    return (
      <Layout>
        <section className="section" style={{marginBottom: '100px'}}>
            <div className="container contact-page-container">
                <div className="columns" style={{paddingBottom: '80px'}}>
                  <div className="column is-1"></div>
                    <div className="column is-10 column-big-responsive">
                        <div className="content has-text-centered" style={{marginTop: '4rem', marginBottom: '4rem'}}>
                            <h3 className="has-text-weight-semibold is-size-3 has-text-centered" style={{marginTop: '50px'}}>
                                Materiały do pobrania
                            </h3>
                            <p className="has-text-justified">
                              Tutaj możesz znaleźć materiały coachingowe, które przygotowałam, by wspomóc Cię w samorozwoju. W razie pytań, skorzystaj z zakładki  <Link to="/kontakt"> kontakt </Link>.
                            </p>
                            
                        </div>
                        <div className="columns">
                            <div className="column is 6 mobile-centered">
                                <h4 className="has-text-weight-semibold is-size-4">
                                    Dbanie o siebie to nie egoizm
                                </h4>
                                <p className="text-pink" style={{marginBottom: '20px'}}>
                                    Workbook
                                </p>
                                <p className="">
                                Skup się na swoim dobrym samopoczuciu zarówno fizycznym, jak i emocjonalnym. Osiągnij równowagę poprzez zdrowe nawyki żywieniowe, aktywność fizyczną i świadome zarządzanie emocjami.                                </p>
                                <a className="btn" href={workbookPdf} download="dbanie_o_siebie_to_nie_egoizm_workbook.pdf" style={{ width: '200px', marginTop: '30px'}}>
                                    Pobierz
                                </a>
                            </div>
                            <div className="column is 6">
                            <div className="">
                                <img src={workbookimg} style={{ width: '80%', marginLeft: '10%', objectFit: 'contain', objectPosition: 'center', borderRadius: '4px', boxShadow: '#cdcdcd 2px 2px 10px'}} />
                            </div>
                            </div>
                        </div>
                    </div>
                    <div className="column is-1"></div>
                </div>
            </div>
            { this.state.showGallery ? 
    <div className="cert-gallery">
      <div className="cert-gallery-background" onClick={() => this.hideGallery()}> </div>
     <img src={this.certs[this.state.currentGalleryIndex]} onClick={() => this.hideGallery()}></img>
     <div className="gallery-arrow gallery-arrow-left" onClick={() => this.leftButtonClicked()}><img src={left} /></div>
     <div className="gallery-arrow gallery-arrow-right" onClick={() => this.rightButtonClicked()} ><img src={right} /></div>
    </div>
      : null }
        </section>
      </Layout>
    )
  }
}